$background: #EEE242;
$primary: #1d575f;
/* Color Overrides */
$nav-pills-link-active-color: $primary;

/* This theme colors */
$theme-colors: (
    "danger": #d20f2f,
    "primary": #1d575f,
    "warning": #f8b34b,
    "secondary": #5f6570
);