@import '~styles/variables.scss';

.title {
    font-weight: 600;
    font-size: 30px;
    color: $primary;
}

.subtitle {
    font-weight: 500;
    font-size: 20px;
    color: $primary;
}

.button-text {
    font-weight: 100;
    flex-grow: 1;
    margin-left: calc(-0.3rem - 24px);
}

.icon {
    font-size: 24px;
    margin-left: 0.3rem;
}

.no-style-link {
    color: inherit;
    text-decoration: none;
}

.svg {
    margin: 0;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
}

.svg-phone {
    background: url('~assets/svg/phone.svg');
    background-size: cover;
}

.svg-internet {
    background: url('~assets/svg/internet.svg');
    background-size: cover;
}

.svg-location {
    background: url('~assets/svg/location.svg');
    background-size: cover;
    width: 1.6rem;
    margin-top: -0.1rem;
}

.svg-instagram {
    background: url('~assets/svg/instagram.svg');
    background-size: cover;
}

.svg-user {
    background: url('~assets/svg/user.svg');
    background-size: cover;
}